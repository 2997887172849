import React, { Fragment } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import dateFormat from "dateformat";

import { createDeepClone } from "../utils/index";
import MyDateRenderer from "../components/MyDateRenderer";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as isoRequestActions from "../actions/IsoRequestActions";

const IsoCertificateEdit  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.iso.loadingCertificate);
    const fbo = useSelector((state) => state.iso.certificate);
    const user = useSelector((state) => state.login.user);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(isoRequestActions.loadIsoCertificate(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
                
        if(fbo) {
            reset(fbo.certificate);
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const onCertificateChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "surveillance1Days" || name === "surveillance2Days"|| name === "certificateExpiryDays") {
                value = value.replace(/[^0-9]/g, '');
            }
        }

        if(value) {
            copy_fboState.certificate[name] = value;
        }
        else if(value === false) {
            copy_fboState.certificate[name] = false;
        }
        else {
            copy_fboState.certificate[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveCertificate = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(isoRequestActions.saveIsoCertificate(fboState, navigate));
        }
    }

    const onCancelCertificate = () => {
        setFBOState(fbo);

        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit Certificate"} />
                    <Row>
                        <Col lg={fboState && fboState.certificate.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Certificate</h5>
                                    {fboState && fboState.canSave &&
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveCertificate}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Certificate
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelCertificate}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Certificate #</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.certificate.certificateNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Certificate Issue Date</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && getFormattedDate(fboState.certificate.certificateIssueDate)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Status</Label>
                                            <select 
                                                className={`form-select ${errors.certificateStatus ? "is-invalid" : ""}`} disabled={fboState && fboState.canSave ? false : true}
                                                value={(fboState && fboState.certificate.certificateStatus) || ""}
                                                {...register("certificateStatus", { required: true })}
                                                onChange={e => onCertificateChange("certificateStatus", e.target.value)}
                                            >
                                                <option value={"Active"}>Active</option>
                                                <option value={"Suspended"}>Suspended</option>
                                                <option value={"Withdrawal"}>Withdrawal</option>
                                                <option value={"Inactive"}>Inactive</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">1st Surveillance Date</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && getFormattedDate(fboState.certificate.surveillance1Date)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Start (before)<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.surveillance1Days ? "is-invalid" : ""}`} readOnly={fboState && fboState.canSave ? false : true}
                                                {...register("surveillance1Days", { required: true })}
                                                value={(fboState && fboState.certificate.surveillance1Days) || ""}
                                                onChange={e => onCertificateChange("surveillance1Days", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Duration<span className="required">*</span></Label>
                                            <select 
                                                className={`form-select ${errors.surveillance1Duration ? "is-invalid" : ""}`} disabled={fboState && fboState.canSave ? false : true}
                                                value={(fboState && fboState.certificate.surveillance1Duration) || ""}
                                                {...register("surveillance1Duration", { required: true })}
                                                onChange={e => onCertificateChange("surveillance1Duration", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                <option value={"7"}>Weekly</option>
                                                <option value={"1"}>Daily</option>
                                                <option value={"0"}>Snooze</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">2nd Surveillance Date</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && getFormattedDate(fboState.certificate.surveillance2Date)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Start (before)<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.surveillance2Days ? "is-invalid" : ""}`} readOnly={fboState && fboState.canSave ? false : true}
                                                {...register("surveillance2Days", { required: true })}
                                                value={(fboState && fboState.certificate.surveillance2Days) || ""}
                                                onChange={e => onCertificateChange("surveillance2Days", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Duration<span className="required">*</span></Label>
                                            <select 
                                                className={`form-select ${errors.surveillance2Duration ? "is-invalid" : ""}`} disabled={fboState && fboState.canSave ? false : true}
                                                value={(fboState && fboState.certificate.surveillance2Duration) || ""}
                                                {...register("surveillance2Duration", { required: true })}
                                                onChange={e => onCertificateChange("surveillance2Duration", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                <option value={"7"}>Weekly</option>
                                                <option value={"1"}>Daily</option>
                                                <option value={"0"}>Snooze</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Certificate Expiry Date</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && getFormattedDate(fboState.certificate.certificateExpiryDate)) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Start (before)<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.certificateExpiryDays ? "is-invalid" : ""}`} readOnly={fboState && fboState.canSave ? false : true}
                                                {...register("certificateExpiryDays", { required: true })}
                                                value={(fboState && fboState.certificate.certificateExpiryDays) || ""}
                                                onChange={e => onCertificateChange("certificateExpiryDays", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Notification Duration<span className="required">*</span></Label>
                                            <select 
                                                className={`form-select ${errors.certificateExpiryDuration ? "is-invalid" : ""}`} disabled={fboState && fboState.canSave ? false : true}
                                                value={(fboState && fboState.certificate.certificateExpiryDuration) || ""}
                                                {...register("certificateExpiryDuration", { required: true })}
                                                onChange={e => onCertificateChange("certificateExpiryDuration", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                <option value={"7"}>Weekly</option>
                                                <option value={"1"}>Daily</option>
                                                <option value={"0"}>Snooze</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        Certificate History
                                    </h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                    <Row className="gy-2">
                                        <Col xxl={12} md={12}>
                                            <Table className="table-hover table-bordered align-middle mb-0">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>Certificate #</th>
                                                        <th>Certificate Issue Date</th>
                                                        <th>1st Surveillance Date</th>
                                                        <th>2nd Surveillance Date</th>
                                                        <th>Certificate Expiry Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fboState && fboState.historyList.length == 0 && <tr><td colSpan={6}>No History</td></tr>}
                                                    {fboState && fboState.historyList.map(function(item, index){
                                                        return(
                                                            <tr key={index}>
                                                                <td>{item.certificateNumber}</td>
                                                                <td><MyDateRenderer value={item.certificateIssueDate} format="mm/dd/yyyy" /></td>
                                                                <td><MyDateRenderer value={item.surveillance1Date} format="mm/dd/yyyy" /></td>
                                                                <td><MyDateRenderer value={item.surveillance2Date} format="mm/dd/yyyy" /></td>
                                                                <td><MyDateRenderer value={item.certificateExpiryDate} format="mm/dd/yyyy" /></td>
                                                                <td>{item.certificateStatus}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        Certificate Tickets
                                    </h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                    <Row className="gy-2">
                                        <Col xxl={12} md={12}>
                                            <Table className="table-hover table-bordered align-middle mb-0">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>Ticket #</th>
                                                        <th>Request Type</th>
                                                        <th>Status</th>
                                                        <th>Certificate #</th>
                                                        {user.isLinkedToCrm && <th>CRM Invoice #</th>}
                                                        {user.isLinkedToCrm && <th>Tax Invoice #</th>}
                                                        {user.isLinkedToCrm && <th>Reference #</th>}
                                                        <th>Action Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fboState && fboState.requestList.length == 0 && <tr><td colSpan={8}>No Ticket(s)</td></tr>}
                                                    {fboState && fboState.requestList.map(function(item, index){
                                                        return(
                                                            <tr key={index}>
                                                                <td>
                                                                    <NavLink  to={`/iso-request-edit/${item.id}`}>{item.requestNumber}</NavLink>
                                                                </td>
                                                                <td>{item.requestType}</td>
                                                                <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                                                <td>{item.certificateNumber}</td>
                                                                {user.isLinkedToCrm && <td>{item.crmInvoiceNumber}</td>}
                                                                {user.isLinkedToCrm && <td>{item.taxInvoiceNumber}</td>}
                                                                {user.isLinkedToCrm && <td>{item.referenceNumber}</td>}
                                                                <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        
                        {fboState && fboState.certificate.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.certificate.id} name={"CERTIFICATE"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default IsoCertificateEdit;