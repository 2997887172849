import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmModalAdvanced = ({ show, onYesClick, onCloseClick, children, size }) => {
  return (
    <Modal size={size || "xl"} isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader className="d-flex justify-content-center">
        Confirmation
      </ModalHeader>
      <ModalBody className="h5 pb-3">
        {children}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button type="button" className="btn btn-primary btn-label w-md" onClick={onYesClick}>
            <i className="ri-check-line label-icon align-bottom"></i> Yes
          </button>
          <button type="button" className="btn btn-dark btn-label w-md" data-bs-dismiss="modal" onClick={onCloseClick}>
            <i className="ri-close-line label-icon align-bottom"></i> No
          </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModalAdvanced;