import { 
    KANBAN_START_LOADING, 
    KANBAN_END_LOADING,
    SET_KANBAN_FILTER_SEARCH,
    SET_KANBAN_FILTER_SERVICE_ID,
    SET_KANBAN_FILTER_MINE_ONLY, 
    KANBAN_DRAFT_LOADED, 
    KANBAN_NEW_LOADED,
    KANBAN_INPROGRESS_LOADED,
    KANBAN_SALES_ACTION_LOADED,
    KANBAN_PD_REVIEW_LOADED,
    KANBAN_PD_SUPPORT_LOADED,
    KANBAN_SAMPLING_LOADED,
    KANBAN_SCHEDULED_LOADED,
    KANBAN_WAITING_CORRECTIVE_ACTION_LOADED,
    KANBAN_PHASE_1_EVALUATION_LOADED,
    KANBAN_INSPECTION_LOADED,
    KANBAN_PHASE_2_EVALUATION_LOADED,
    KANBAN_FINAL_DECISION_LOADED,
    KANBAN_COMPLETED_LOADED,
    KANBAN_CLOSED_LOADED,
    KANBAN_STOPPED_LOADED,
    KANBAN_ABORTED_LOADED,
    KANBAN_GROUP_LOADED,
    KANBAN_GROUP_USER_LOADED,
    KANBAN_PD_GROUP_LOADED,
    KANBAN_PD_GROUP_USER_LOADED,
    KANBAN_USER_SEARCH_LOADED
} from "../constants/KanbanConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadKanban(status, search, serviceId, mineOnly) {
    return async (dispatch) => {
        
        await dispatch({ type: KANBAN_START_LOADING });

        try {

            var data = await getApiData("Ticket/GetTicketGroupFBO", { status, search, serviceId, mineOnly }, null);
            
            if(data.success) {
                if(status === null || status === "" || status === "Draft") {
                    await dispatch({
                        type: KANBAN_DRAFT_LOADED,
                        payload: data.responseEntities[0].draftList
                    });
                }

                if(status === null || status === "" || status === "New") {
                    await dispatch({
                        type: KANBAN_NEW_LOADED,
                        payload: data.responseEntities[0].newList
                    });
                }

                if(status === null || status === "" || status === "In Progress") {
                    await dispatch({
                        type: KANBAN_INPROGRESS_LOADED,
                        payload: data.responseEntities[0].inProgressList
                    });
                }

                if(status === null || status === "" || status === "Sales Action") {
                    await dispatch({
                        type: KANBAN_SALES_ACTION_LOADED,
                        payload: data.responseEntities[0].salesActionList
                    });
                }

                if(status === null || status === "" || status === "PD Review") {
                    await dispatch({
                        type: KANBAN_PD_REVIEW_LOADED,
                        payload: data.responseEntities[0].pdReviewList
                    });
                }

                if(status === null || status === "" || status === "PD Support") {
                    await dispatch({
                        type: KANBAN_PD_SUPPORT_LOADED,
                        payload: data.responseEntities[0].pdSupportList
                    });
                }

                if(status === null || status === "" || status === "Sampling") {
                    await dispatch({
                        type: KANBAN_SAMPLING_LOADED,
                        payload: data.responseEntities[0].samplingList
                    });
                }

                if(status === null || status === "" || status === "Scheduled") {
                    await dispatch({
                        type: KANBAN_SCHEDULED_LOADED,
                        payload: data.responseEntities[0].scheduledList
                    });
                }

                if(status === null || status === "" || status === "Waiting Corrective Action") {
                    await dispatch({
                        type: KANBAN_WAITING_CORRECTIVE_ACTION_LOADED,
                        payload: data.responseEntities[0].waitingCorrectiveActionList
                    });
                }

                if(status === null || status === "" || status === "Phase 1 Evaluation") {
                    await dispatch({
                        type: KANBAN_PHASE_1_EVALUATION_LOADED,
                        payload: data.responseEntities[0].phase1EvaluationList
                    });
                }

                if(status === null || status === "" || status === "Inspection") {
                    await dispatch({
                        type: KANBAN_INSPECTION_LOADED,
                        payload: data.responseEntities[0].inspectionList
                    });
                }

                if(status === null || status === "" || status === "Phase 2 Evaluation") {
                    await dispatch({
                        type: KANBAN_PHASE_2_EVALUATION_LOADED,
                        payload: data.responseEntities[0].phase2EvaluationList
                    });
                }

                if(status === null || status === "" || status === "Final Decision") {
                    await dispatch({
                        type: KANBAN_FINAL_DECISION_LOADED,
                        payload: data.responseEntities[0].finalDecisionList
                    });
                }

                if(status === null || status === "" || status === "Completed") {
                    await dispatch({
                        type: KANBAN_COMPLETED_LOADED,
                        payload: data.responseEntities[0].completedList
                    });
                }

                if(status === null || status === "" || status === "Closed") {
                    await dispatch({
                        type: KANBAN_CLOSED_LOADED,
                        payload: data.responseEntities[0].closedList
                    });
                }

                if(status === null || status === "" || status === "Stopped") {
                    await dispatch({
                        type: KANBAN_STOPPED_LOADED,
                        payload: data.responseEntities[0].stoppedList
                    });
                }

                if(status === null || status === "" || status === "Aborted") {
                    await dispatch({
                        type: KANBAN_ABORTED_LOADED,
                        payload: data.responseEntities[0].abortedList
                    });
                }

                await dispatch({
                    type: KANBAN_GROUP_LOADED,
                    payload: data.responseEntities[0].groupList
                });

                await dispatch({
                    type: KANBAN_GROUP_USER_LOADED,
                    payload: data.responseEntities[0].groupUserList
                });

                await dispatch({
                    type: KANBAN_PD_GROUP_LOADED,
                    payload: data.responseEntities[0].pdGroupList
                });

                await dispatch({
                    type: KANBAN_PD_GROUP_USER_LOADED,
                    payload: data.responseEntities[0].pdGroupUserList
                });

                await dispatch({
                    type: KANBAN_USER_SEARCH_LOADED,
                    payload: data.responseEntities[0].userSearches
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: KANBAN_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: KANBAN_END_LOADING });
        }
    };
}

export function setKanbanFilterSearch(search) {
    return async (dispatch) => {
        await dispatch({
            type: SET_KANBAN_FILTER_SEARCH,
            payload: search
        });
    };
}

export function setKanbanFilterServiceId(serviceId) {
    return async (dispatch) => {
        await dispatch({
            type: SET_KANBAN_FILTER_SERVICE_ID,
            payload: serviceId
        });
    };
}

export function setKanbanFilterMineOnly(mineOnly) {
    return async (dispatch) => {
        await dispatch({
            type: SET_KANBAN_FILTER_MINE_ONLY,
            payload: mineOnly
        });
    };
}

export function assignTicket(ticketId, userId, assignMe, items, index) {
    return async (dispatch) => {
        
        await dispatch({ type: KANBAN_START_LOADING });

        try {

            var data = await postApiJsonData("Ticket/AssignTicket", { ticketId, userId, assignMe }, null);
            
            if(data.success) {
                if(data.responseEntities[0].status === "New" || data.responseEntities[0].status === "In Progress") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_NEW_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Phase 1 Evaluation") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_PHASE_1_EVALUATION_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "PD Review") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_PD_REVIEW_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "PD Support") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_PD_SUPPORT_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Sampling") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_SAMPLING_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Final Decision") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_FINAL_DECISION_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Inspection") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: KANBAN_INSPECTION_LOADED,
                        payload: items
                    });
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: KANBAN_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: KANBAN_END_LOADING });
        }
    };
}