import { LOGIN_START_LOADING, LOGIN_END_LOADING, LOGIN_LOADED, LOGIN_TOKEN_LOADED, LOGIN_DATA_LOADED, CHANGE_PASSWORD_LOADED } from "../constants/LoginConstants";

import * as commonActions from "./CommonActions";
import { getApiData, postApiJsonData  } from "../utils/index";

export function getUserInfo(data) {
    var user_info = data.responseEntities[0].simpleUser;
    user_info.isAdmin = data.responseEntities[0].isAdmin;
    user_info.isManager = data.responseEntities[0].isManager;
    user_info.isGroupLeader = data.responseEntities[0].isGroupLeader;
    user_info.isTechnicalUser = data.responseEntities[0].isTechnicalUser;
    user_info.isPdReviewer = data.responseEntities[0].isPdReviewer;
    user_info.isDecisionMaker = data.responseEntities[0].isDecisionMaker;
    user_info.isReporter = data.responseEntities[0].isReporter;
    user_info.isInspector = data.responseEntities[0].isInspector;
    user_info.isInspectorCoordinator = data.responseEntities[0].isInspectorCoordinator;
    user_info.isLabReportData = data.responseEntities[0].isLabReportData;
    user_info.isQuality = data.responseEntities[0].isQuality;
    user_info.isCompliance = data.responseEntities[0].isCompliance;
    user_info.isViewAllClosed = data.responseEntities[0].isViewAllClosed;
    user_info.isIsoDocumentController = data.responseEntities[0].isIsoDocumentController;
    user_info.isIsoDecisionMaker = data.responseEntities[0].isIsoDecisionMaker;
    user_info.isIsoAuditor = data.responseEntities[0].isIsoAuditor;
    user_info.isIsoTechnicalReviewer = data.responseEntities[0].isIsoTechnicalReviewer;
    user_info.isIsoManager = data.responseEntities[0].isIsoManager;

    user_info.canAddRequest = data.responseEntities[0].canAddRequest;
    user_info.canAssignMe = data.responseEntities[0].canAssignMe;
    user_info.canAssignMePd = data.responseEntities[0].canAssignMePd;
    user_info.canAssignMeDecisionMaker = data.responseEntities[0].canAssignMeDecisionMaker;
    user_info.canAssignMeInspection = data.responseEntities[0].canAssignMeInspection;
    user_info.canAssignAny = data.responseEntities[0].canAssignAny;
    user_info.canManageCompany = data.responseEntities[0].canManageCompany;
    user_info.canManageUser = data.responseEntities[0].canManageUser;
    user_info.canManageGroup = data.responseEntities[0].canManageGroup;
    user_info.canAddCustomer = data.responseEntities[0].canAddCustomer;
    user_info.canManageCustomer = data.responseEntities[0].canManageCustomer;
    user_info.canManageImporter = data.responseEntities[0].canManageImporter;
    user_info.canManageExporter = data.responseEntities[0].canManageExporter;
    user_info.canManageManufacturer = data.responseEntities[0].canManageManufacturer;
    user_info.canManageProduct = data.responseEntities[0].canManageProduct;

    user_info.showDraft = data.responseEntities[0].showDraft;
    user_info.showNew = data.responseEntities[0].showNew;
    user_info.showInProgress = data.responseEntities[0].showInProgress;
    user_info.showSalesAction = data.responseEntities[0].showSalesAction;
    user_info.showPdReview = data.responseEntities[0].showPdReview;
    user_info.showPdSupport = data.responseEntities[0].showPdSupport;
    user_info.showSampling = data.responseEntities[0].showSampling;
    user_info.showScheduled = data.responseEntities[0].showScheduled;
    user_info.showWaitingCorrectiveAction = data.responseEntities[0].showWaitingCorrectiveAction;
    user_info.showPhase1Evaluation = data.responseEntities[0].showPhase1Evaluation;
    user_info.showInspection = data.responseEntities[0].showInspection;
    user_info.showPhase2Evaluation = data.responseEntities[0].showPhase2Evaluation;
    user_info.showFinalDecision = data.responseEntities[0].showFinalDecision;
    user_info.showCompleted = data.responseEntities[0].showCompleted;
    user_info.showClosed = data.responseEntities[0].showClosed;
    user_info.showStopped = data.responseEntities[0].showStopped;
    user_info.showAborted = data.responseEntities[0].showAborted;

    user_info.showIsoDraft = data.responseEntities[0].showIsoDraft;
    user_info.showIsoNew = data.responseEntities[0].showIsoNew;
    user_info.showIsoSalesAction = data.responseEntities[0].showIsoSalesAction;
    user_info.showIsoCalculateManDays = data.responseEntities[0].showIsoCalculateManDays;
    user_info.showIsoSalesSupport = data.responseEntities[0].showIsoSalesSupport;
    user_info.showIsoCustomerApproved = data.responseEntities[0].showIsoCustomerApproved;
    user_info.showIsoAuditInProgress = data.responseEntities[0].showIsoAuditInProgress;
    user_info.showIsoTechnicalReview = data.responseEntities[0].showIsoTechnicalReview;
    user_info.showIsoFinalDecision = data.responseEntities[0].showIsoFinalDecision;
    user_info.showIsoCompleted = data.responseEntities[0].showIsoCompleted;
    user_info.showIsoClosed = data.responseEntities[0].showIsoClosed;
    user_info.showIsoAborted = data.responseEntities[0].showIsoAborted;

    return user_info;
}

export function getUserData(data) {
    var user_data = {
        serviceList: data.responseEntities[0].serviceList
        , actionTypeList: data.responseEntities[0].actionTypeList
        , currencyList: data.responseEntities[0].currencyList
        , documentTypeList: data.responseEntities[0].documentTypeList
        , paymentStatusList: data.responseEntities[0].paymentStatusList
        , ticketStatusList: data.responseEntities[0].ticketStatusList
        , technicalRegulationList: data.responseEntities[0].technicalRegulationList
        , caiList: data.responseEntities[0].caiList
    }

    return user_data;
}

export function getUserToken(data) {
    return data.responseEntities[0].token;
}

export function loadLogin(email, password) {
    return async (dispatch) => {
        
        await dispatch({ type: LOGIN_START_LOADING });

        try {
            
            const data = await postApiJsonData("UserProfile/Login", { email, password }, null, false);
            
            if(data.success) {
                var user_info = getUserInfo(data);
                var user_data = getUserData(data);
                var user_token = getUserToken(data);

                localStorage.setItem("ticketing_user_info", JSON.stringify(user_info));
                localStorage.setItem("ticketing_user_data", JSON.stringify(user_data));
                localStorage.setItem("ticketing_user_token", user_token);

                await dispatch({
                    type: LOGIN_LOADED,
                    payload: user_info
                });

                await dispatch({
                    type: LOGIN_TOKEN_LOADED,
                    payload: data.responseEntities[0].token
                });

                await dispatch({
                    type: LOGIN_DATA_LOADED,
                    payload: user_data
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
                                    
			await dispatch({ type: LOGIN_END_LOADING });
        }
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LOGIN_END_LOADING });
        }
    };
}

export function loadLogout() {
    return async (dispatch) => {
        
        await dispatch({
            type: LOGIN_LOADED,
            payload: null
        });

        await dispatch({
            type: LOGIN_TOKEN_LOADED,
            payload: ""
        });

        await dispatch({
            type: LOGIN_DATA_LOADED,
            payload: {
                serviceList: []
                , actionTypeList: []
                , currencyList: []
                , documentTypeList: []
                , paymentStatusList: []
                , ticketStatusList: []
                , technicalRegulationList: []
                , caiList: []
            }
        });

        localStorage.removeItem("ticketing_user_info");
        localStorage.removeItem("ticketing_user_data");
        localStorage.removeItem("ticketing_user_token");
    };
}

export function forgotPassword(email) {
    return async (dispatch) => {
        
        await dispatch({ type: LOGIN_START_LOADING });

        try {
            
            const data = await postApiJsonData("UserProfile/ForgotPassword", { email }, null, false);
            
            if(data.success) {
                dispatch(commonActions.setSuccessMessage("Password reset email is sent to your email, please follow the instruction in mail to reset password."));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
                                    
			await dispatch({ type: LOGIN_END_LOADING });
        }
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LOGIN_END_LOADING });
        }
    };
}

export function loadChangePassword(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: LOGIN_START_LOADING });

        try {
            
            var data = await getApiData("UserProfile/GetChangePasswordFBO", { id }, false);
            
            if(data.success) {
                await dispatch({
                    type: CHANGE_PASSWORD_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                navigate('/');
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LOGIN_END_LOADING });
        } 
        catch (error) {
            navigate('/');
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LOGIN_END_LOADING });
        }
    };
}

export function saveChangePassword(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: LOGIN_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("UserProfile/SaveChangePasswordFBO", null, fbo, false);
            
            if(data.success) {
                navigate('/');
                dispatch(commonActions.setSuccessMessage("Password Changed Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: LOGIN_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: LOGIN_END_LOADING });
        }
    };
}

export async function changeCompanyAndModule(companyId, moduleName) {
    var returnData = {
        success: false,
        error: null
    };

    try {

        var data = await postApiJsonData("UserProfile/ChangeCompanyAndModule", { companyId, moduleName }, null, true);
        
        if(data.success) {
            returnData.success = true;

            var user_info = getUserInfo(data);
            var user_data = getUserData(data);
            var user_token = getUserToken(data);

            localStorage.setItem("ticketing_user_info", JSON.stringify(user_info));
            localStorage.setItem("ticketing_user_data", JSON.stringify(user_data));
            localStorage.setItem("ticketing_user_token", user_token);
        }
        else {
            returnData.error = data.errorDesc;
        }
    } 
    catch (error) {
        returnData.error = error;
    }

    return returnData;
}