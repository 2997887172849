import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = ({ message, show, onYesClick, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader className="d-flex justify-content-center">
        Confirmation
      </ModalHeader>
      <ModalBody className="h5 pb-3 text-center">
        {message}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button type="button" className="btn btn-primary btn-label w-md" onClick={onYesClick}>
          <i className="ri-check-line label-icon align-bottom"></i> Yes
        </button>
        <button type="button" className="btn btn-dark btn-label w-md" data-bs-dismiss="modal" onClick={onCloseClick}>
          <i className="ri-close-line label-icon align-bottom"></i> No
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;